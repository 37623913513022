import { API_URL } from "../Constants/APIURL";
import useAuth from "../Hooks/useAuth";

const useLogout = () => {
  const { setAuth } = useAuth();

  const logout = async () => {
    setAuth({});
    try {
      const response = await fetch(`${API_URL}/logout`, {
        credentials: "include",
      });
    } catch (err) {
      console.log(err);
    }
  };
  return logout;
};

export default useLogout;
