import React, { useEffect, useState } from "react";
import "../../App.css";
import SideMenu from "../../Components/SideMenu";
import Footer from "../../Components/Footer";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Alert from "../../Components/Alert";
import { useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { parseISO } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AWS from "../../Constants/aws-config";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

const EditFooterBanner = () => {
  const { bannerID } = useParams();

  const [bannerType, setBannerType] = useState("");
  const [bannerTitle, setBannerTitle] = useState("");
  const [initialBannerImg, setInitialBannerImg] = useState("");
  const [updatedBannerImg, setUpdatedBannerImg] = useState("");
  const [previewImg, setPreviewImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    getFooterBanner();
  }, []);

  const getFooterBanner = () => {
    setLoading(true);
    fetch(`${API_URL}/getFooterBannerById?bannerID=${bannerID}`)
      .then(async (response) => {
        const jsonRes = await response.json();
        const bannerData = jsonRes.data;
        setBannerType(bannerData.bannerType);
        setBannerTitle(bannerData.bannerTitle);
        setStartDate(parseISO(bannerData.startDate));
        setEndDate(parseISO(bannerData.endDate));
        setInitialBannerImg(bannerData.bannerImage);
        setPreviewImg(bannerData.bannerImage);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching banner", error);
        setIsError(true);
        setMessage("Error fetching data");
        setLoading(false);
      });
  };

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    if (!imageFile) {
      return;
    }
    setUpdatedBannerImg(imageFile);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imageFile);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (updatedBannerImg) {
      const s3 = new AWS.S3();
      const bucketName = "dcapp-bucket";
      const folderPath = "images/footerBanner/";
      const oldImageKey = initialBannerImg.replace(
        "https://dcapp-bucket.s3.ap-southeast-1.amazonaws.com/images/footerBanner/",
        ""
      );
      const newImageKey = updatedBannerImg.name;
      const deleteParams = {
        Bucket: bucketName,
        Key: folderPath + oldImageKey,
      };
      s3.deleteObject(deleteParams, (deleteErr, deleteData) => {
        if (deleteErr) {
          console.error("Error deleting old footer banner", deleteErr);
        } else {
          console.log(
            "Old footer banner deleted successfully",
            deleteData,
            oldImageKey
          );
        }
        //upload new footer banner to s3
        const uploadParams = {
          Bucket: bucketName,
          Key: folderPath + newImageKey,
          Body: updatedBannerImg,
        };
        s3.upload(uploadParams, (err, data) => {
          if (err) {
            console.error("Error uploading new footer banner", data.Location);
          } else {
            setInitialBannerImg(updatedBannerImg.name); // set initial image with updated image
          }
        });
      });
    }
    const payload = {
      bannerType: bannerType,
      bannerTitle: bannerTitle,
      startDate: startDate,
      endDate: endDate,
      bannerImage: updatedBannerImg
        ? "https://dcapp-bucket.s3.ap-southeast-1.amazonaws.com/images/footerBanner/" +
          updatedBannerImg.name
        : initialBannerImg,
    };

    fetch(`${API_URL}/updateFooterBanner?id=${bannerID}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage("HTTP Update Error");
          setLoading(false);
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setIsError(false);
        setLoading(false);
        setMessage("File updated successfully");
      })
      .catch((error) => {
        setIsError(true);
        setMessage("Update Error");
        setLoading(false);
        console.error("Update error:", error);
      })
      .finally(() => {
        setUpdatedBannerImg(null); //reset state
      });
  };

  if (loading) {
    return (
      <div className="content-wrapper">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          backgroundColor: "#f4f6f9",
        }}
      >
        <SideMenu />
        {/* Main content */}
        <div className="main-container">
          <Title title={"Edit Footer Banner"}></Title>
          {message ? <Alert isError={isError} message={message} /> : null}
          <section className="content">
            {/* <!-- general form elements --> */}
            <div class="card card-primary">
              {/* <!-- form start --> */}
              <form>
                <div class="card-body">
                  <div class="form-group">
                    <label htmlFor="title">Banner Type </label>
                    <input
                      onChange={(e) => setBannerType(e.target.value)}
                      type="text"
                      class="form-control"
                      id="title"
                      value={bannerType}
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label htmlFor="title">Banner Title </label>
                    <input
                      onChange={(e) => setBannerTitle(e.target.value)}
                      type="text"
                      class="form-control"
                      id="title"
                      value={bannerTitle}
                    />
                  </div>
                  <div class="form-group">
                    <label htmlFor="bannertitle">Start Date</label>
                    <DatePicker
                      showIcon
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div class="form-group">
                    <label htmlFor="bannertitle">End Date</label>
                    <DatePicker
                      showIcon
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                  <div class="form-group">
                    <label htmlFor="image-input">File input</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          id="image-input"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                    {previewImg && (
                      <img
                        src={previewImg}
                        alt="Preview"
                        style={{ maxWidth: "50%", marginTop: "10px" }}
                      />
                    )}
                  </div>
                </div>
                {/* <!-- /.card-body --> */}

                <div class="card-footer">
                  <button onClick={onSubmitHandler} class="btn btn-primary">
                    Submit
                  </button>
                </div>
                {/* <span style={{ color: isError ? "red" : "green" }}>
              {message ? getMessage() : null}
            </span> */}
              </form>
            </div>
            {/* <!-- /.card --> */}

            {/* <!-- general form elements --> */}
          </section>
        </div>
      </div>
    </>
  );
};

export default EditFooterBanner;
