import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import SideMenu from "../../Components/SideMenu";
//import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
import { API_URL } from "../../Constants/APIURL";
import { DataGrid, GridToolbar, GRID_DATETIME_COL_DEF } from "@mui/x-data-grid";
import "./Report.css";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 4.8132256, // Replace with your desired center coordinates
  lng: 108.4752564,
};

const FeedbacksReport = () => {
  //const [outletList, setOutletList] = useState([]);
  //const [outletName, setOutletName] = useState([]);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [detailsFeedback, setDetailsFeedback] = useState([]);
  const [loading, setLoading] = useState(false);

  //const [totalAmount, setTotalAmount] = useState(0.00);

  useEffect(() => {
    document.title = "Feedback Report";
    //getSalesData();
  }, []);

  //the outlet name need to use trim(), else there will be unintended spacing behind the outlet name
  const getFeedback = () => {
    setLoading(true);
    if (dateFrom == null || dateFrom == "" || dateTo == null || dateTo == "") {
      setLoading(false);
      return null;
    } else {
      fetch(`${API_URL}/getFeedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dateFrom: dateFrom,
          dateTo: dateTo,
        }),
      }).then(async (response) => {
        const jsonRes = await response.json();
        if (response.status == 200) {
          var feedbackDataJSON = jsonRes.feedbacksData;
          setDetailsFeedback(feedbackDataJSON);
        }
        setLoading(false);
      });
    }
  };

  const columns = [
    { field: "feedbackID", headerName: "Feedback ID", width: 70 },
    { field: "userID", headerName: "User ID", width: 70 },
    { field: "rating", headerName: "Rating", width: 70 },
    { field: "topic", headerName: "Topic", width: 110 },
    { field: "feedback", headerName: "Feedback", width: 400 },
    { field: "outlet", headerName: "Outlet", width: 250 },
    { field: "createdAt", headerName: "Date Time", width: 200 },
  ];

  function getRowId(row) {
    return row.feedbackID;
  }
  if (loading) {
    return (
      <div className="content-wrapper">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <SideMenu />
      {/* Main content */}
      {/* <div className="content-wrapper"> */}
      {/* <div>
        <Title title={"Send Notification"}></Title>

      </div> */}
      <div className="form-container">
        <h2>Feedback Report</h2>

        <form>
          <div class="form-group">
            <label>From</label>
            <input
              type="date"
              name="dateFrom"
              className="form-control"
              onChange={(e) => setDateFrom(e.target.value)}
            />
          </div>
          <div class="form-group">
            <label>To</label>
            <input
              type="date"
              className="form-control"
              name="dateTo"
              onChange={(e) => setDateTo(e.target.value)}
            />
          </div>
        </form>
        <button onClick={getFeedback} className="btn btn-primary mb-4">
          Query
        </button>
        <br></br>

        <DataGrid
          rows={detailsFeedback}
          columns={columns}
          getRowHeight={() => "auto"}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          getRowId={getRowId}
          pageSizeOptions={[10, 50, 100]}
          //checkboxSelection
          //onRowSelectionModelChange={getSelectedRow} //when checkbox is checked, will pass as param to the function
          slots={{ toolbar: GridToolbar }}
          sx={{ height: 1 / 2 }}
        />
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default FeedbacksReport;
