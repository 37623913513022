import React from "react";

const Alert = ({ isError, message }) => {
  return (
    <div>
      {isError ? (
        <div class="alert alert-danger" role="alert">
          {message}
        </div>
      ) : (
        <div class="alert alert-success" role="alert">
          {message}
        </div>
      )}
    </div>
  );
};

export default Alert;
