import React, { useState, useEffect } from "react";
import "../../App.css";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Table from "../../Components/Table";
import { useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";
import SideMenu from "../../Components/SideMenu";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

const ManageAdmin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [adminData, setAdminData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    loadAdmin();
  }, []);

  const loadAdmin = () => {
    setLoading(true);
    fetch(`${API_URL}/getAdminList`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
          setLoading(false);
        }
        return response.json();
      })
      .then((adminData) => {
        setAdminData(adminData.data);
        console.log(adminData.data);
        setLoading(false);
      })
      .catch((error) => {
        setIsError(true);
        setMessage(error);
        console.log("Error fetching adminusers", error);
        setLoading(false);
      });
  };

  const handleDeleteAdmin = async (id, name) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete " + name + " ?"
    );

    if (confirmDelete) {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/deleteAdmin?id=${id}`, {
          method: "DELETE",
        });

        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
          setLoading(false);
          return;
        }
        setAdminData((prevAdminData) =>
          prevAdminData.filter((item) => item.adminID !== id)
        );
        setMessage(`Admin deleted successfully`);
        setLoading(false);
      } catch (error) {
        console.log("Error deleting news", error);
      }
    }
  };
  const columns = [
    {
      field: "adminID",
      headerName: "ID",
      width: 80,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "role",
      headerName: "Role",
      width: 200,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <button
            class="btn btn btn-outline-danger"
            onClick={(e) =>
              handleDeleteAdmin(params.row.adminID, params.row.name)
            }
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="content-wrapper">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
      }}
    >
      <SideMenu />
      {/* Main content */}
      <div className="form-container">
        <Title title={"Manage Admin"}></Title>
        {message ? <Alert isError={isError} message={message} /> : null}
        <section className="content">
          <div className="container-fluid">
            <div className="text-right mb-2">
              <button
                onClick={() => navigate("/addAdmin")}
                className="btn btn-primary text-right"
              >
                Add Admin
              </button>
            </div>
            <Table columns={columns} rows={adminData} id={"adminID"}></Table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ManageAdmin;
