import React, { useState, useEffect } from "react";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import Title from "../../Components/Title";
import { API_URL } from "../../Constants/APIURL";
import Table from "../../Components/Table";
import Alert from "../../Components/Alert";
import SideMenu from "../../Components/SideMenu";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

const ManageFooter = () => {
  const [footerBanner, setFooterBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadBanner();
  }, []);

  const loadBanner = () => {
    fetch(`${API_URL}/getFooterBanner`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          setIsError(true);
          setMessage(`HTTP error: ${response.status}`);
          setLoading(false);
        }
        return response.json();
      })
      .then((bannerData) => {
        setFooterBanner(bannerData);
        console.log(bannerData);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching footer banner", error);
        setLoading(false);
      });
  };

  const handleEditBanner = (bannerID) => {
    navigate(`/editFooterBanner/${bannerID}`);
  };

  const columns = [
    {
      field: "bannerID",
      headerName: "ID",
      width: 80,
    },
    {
      field: "bannerType",
      headerName: "Banner Type",
      width: 200,
    },
    {
      field: "bannerTitle",
      headerName: "Banner Title",
      width: 150,
      wrap: true,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
      wrap: true,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 150,
      wrap: true,
    },
    {
      field: "bannerImage",
      headerName: "Banner Image",
      width: 200,
      renderCell: (params) => (
        <img
          src={params.row.bannerImage}
          style={{ width: "200px", height: "150px" }}
        />
      ),
    },
    {
      field: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <button onClick={(e) => handleEditBanner(params.row.bannerID)}>
            Edit
          </button>
        </>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="content-wrapper">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        backgroundColor: "#f4f6f9",
      }}
    >
      <SideMenu />
      {/* Main Content */}
      <div className="form-container">
        <Title title={"Manage Footer"}></Title>
        {message ? <Alert isError={isError} message={message} /> : null}
        <section className="content">
          <div className="container-fluid">
            <Table
              columns={columns}
              rows={footerBanner}
              id={"bannerID"}
              rowHeight={150}
            ></Table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ManageFooter;
